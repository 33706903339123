<template>
  <div>
    <v-row class='px-2 mb-2'>
      <v-col
        cols='12'
        md='6'
        lg='4'
      >
        <v-select
          v-model='user_id'
          :items='userList'
          :label="$i18n.t('appointment')"
          item-text='user_fullname'
          item-value='user_id'
          hide-details
          dense
          outlined
        ></v-select>
      </v-col>
      <v-col
        cols='12'
        md='6'
        lg='4'
      >
        <v-text-field
          v-model='searchtext'
          hide-details
          dense
          :label="$i18n.t('search')"
          :placeholder="$i18n.t('wordingSearch')"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col
        cols='12'
        md='6'
        lg='4'
      >
        <v-dialog
          ref='date'
          v-model.trim='isShowDate'
          :return-value.sync='appoint_date_start'
          width='290px'
        >
          <template v-slot:activator='{ on, attrs }'>
            <v-text-field
              v-model.trim='appoint_date_start'
              :label="$i18n.t('date')"
              hide-details
              dense
              outlined
              readonly
              v-bind='attrs'
              v-on='on'
            >
              <template v-slot:append>
                <v-tooltip
                  color='#212121'
                  top
                >
                  <template v-slot:activator='{ on, attrs }'>
                    <v-icon
                      color='primary'
                      v-bind='attrs'
                      v-on='on'
                      @click="appoint_date_start = ''"
                    >
                      {{ icons.mdiCalendarBlank }}
                    </v-icon>
                  </template>
                  <span>{{ $i18n.t('all_dates') }}</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model='appoint_date_start'
            class=''
            :locale='$i18n.locale'
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color='primary'
              @click='$refs.date.save(appoint_date_start)'
            >
              {{ $i18n.t('confirm') }}
            </v-btn>
            <v-btn
              text
              color='primary'
              @click='isShowDate = false'
            >
              {{ $i18n.t('cancel') }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>
    <v-data-table
      :headers='columns'
      :items='dataTableList'
      :options.sync='options'
      :server-items-length='totalDataTableList'
      :footer-props='footer'
      :loading='loading'
      disable-sort
      hide-default-footer
      mobile-breakpoint='0'
      item-key='appoint_id_pri'
      :loading-text="$t('data_loading')"
      :no-data-text="$t('no_information')"
    >
      <template v-slot:[`item.appoint_id_pri`]='{ index }'>
        {{ segmentId + index + 1 }}
      </template>
      <template v-slot:[`item.appoint_date`]='{ item }'>
        <ConverseDate :date='item.appoint_date' />
      </template>
      <template v-slot:[`item.appoint_status_id`]='{ item }'>
        <AppointmentStatus :type='+item.appoint_status_id' />
      </template>
      <template v-slot:[`item.appoint_status_line`]='{ item }'>
        <AppointmentLineStatus :status='+item.appoint_status_line' />
        /
        <AppointmentSmsStatus :status='+item.appoint_status_sms' />
      </template>
      <template v-slot:[`item.actions`]='{ item }'>
        <v-tooltip
          color='#212121'
          top
        >
          <template v-slot:activator='{ on, attrs }'>
            <v-btn
              v-if='!item.appoint_gid'
              x-small
              class='rounded-pill'
              outlined
              v-bind='attrs'
              color='error'
              :disabled="item.appoint_syn_id == '0'"
              @click='connect_google_plus(item.appoint_id_pri)'
              v-on='on'
            >
              <v-icon>{{ icons.mdiGooglePlus }}</v-icon>
            </v-btn>
          </template>
          <span>{{
              $t('connect_google_calendar')
            }}</span>
        </v-tooltip>
        <v-tooltip
          color='#212121'
          top
        >
          <template v-slot:activator='{ on, attrs }'>
            <v-btn
              v-if='item.appoint_gid'
              x-small
              class='rounded-pill'
              color='error'
              v-bind='attrs'
              :disabled="item.appoint_syn_id == '0'"
              @click='connect_google_plus(item.appoint_id_pri)'
              v-on='on'
            >
              <v-icon>{{ icons.mdiGooglePlus }}</v-icon>
            </v-btn>
          </template>
          <span>{{
              $t('connect_google_calendar')
            }}</span>
        </v-tooltip>
        <v-tooltip
          color='#212121'
          top
        >
          <template v-slot:activator='{ on, attrs }'>
            <v-btn
              v-bind='attrs'
              icon
              color='success'
              @click='showDetailCustomer(item.customer_id_pri, item.appoint_id_pri)'
              v-on='on'
            >
              <v-icon>{{ icons.mdiCheck }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('manage') }}</span>
        </v-tooltip>
        <v-tooltip
          color='#212121'
          top
        >
          <template v-slot:activator='{ on, attrs }'>
            <v-btn
              v-bind='attrs'
              icon
              color='info'
              @click='appointmentId = item.appoint_id_pri;isShowDetail = true'
              v-on='on'
            >
              <v-icon>{{ icons.mdiEyeOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('detail') }}</span>
        </v-tooltip>
        <v-tooltip
          color='#212121'
          top
        >
          <template v-slot:activator='{ on, attrs }'>
            <v-btn
              icon
              color='warning'
              v-bind='attrs'
              :loading='loadingUpdate'
              :disabled='loadingUpdate'
              @click='pending(item.appoint_id_pri)'
              v-on='on'
            >
              <v-icon>{{ icons.mdiAlertOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('pending') }}</span>
        </v-tooltip>
        <v-tooltip
          color='#212121'
          top
        >
          <template v-slot:activator='{ on, attrs }'>
            <v-btn
              v-bind='attrs'
              icon
              color='primary'
              @click='isPrintAppointment = true ;appointmentId = item.appoint_id_pri'

              v-on='on'
            >
              <v-icon>{{ icons.mdiPrinter }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('print') }}</span>
        </v-tooltip>
        <v-tooltip
          color='#212121'
          top
        >
          <template v-slot:activator='{ on, attrs }'>
            <v-btn
              icon
              color='error'
              v-bind='attrs'
              @click="comment='';appointmentId = item.appoint_id_pri;isCancel = true"
              v-on='on'
            >
              <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('cancel') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <Pagination
      :page-data='options.page'
      :page-count='totalPage'
      :segment-id='+segmentId '
      :count-list='dataTableList.length'
      :total='+totalDataTableList'
      @pageChanged='(page)=>{options.page = page ; fetchDataTable()}'
      @itemChanged='(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}'
    />
    <DetailAppointment
      v-model='isShowDetail'
      :appoint-id='appointmentId'
      @onUpdate='fetchDataTable'
    />
    <v-dialog
      v-model='isCancel'
      persistent
      max-width='450'
    >
      <v-card>
        <v-card-title>
          {{ $t('confirm_cancellation') }}
        </v-card-title>
        <v-form
          ref='formCancel'
          @submit.prevent='confirmCancel'
        >
          <v-card-text>
            <v-textarea
              v-model='comment'
              :label="$t('note_of_cancellation')"
              :rules='[required]'
              outlined
              dense
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color='primary'
              type='submit'
              :loading='loadingCancel'
              :disabled='loadingCancel'
            >
              {{ $t('confirm') }}
            </v-btn>
            <v-btn
              color='secondary '
              outlined
              @click='isCancel =false'
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <AppointmentOptions
      :id='[appointmentId]'
      v-model='isPrintAppointment'
    />
  </div>
</template>

<script>
import {
  mdiCalendarBlank,
  mdiGooglePlus,
  mdiCheck,
  mdiEyeOutline,
  mdiAlertOutline,
  mdiPrinter,
  mdiDeleteOutline,
} from '@mdi/js'
import { ref, watch, onMounted } from '@vue/composition-api'
import userRole from '@/api/userRole'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import appointment from '@/api/appointment/appointment'
import { i18n } from '@/plugins/i18n'
import AppointmentStatus from '@/components/basicComponents/AppointmentStatus.vue'
import AppointmentLineStatus from '@/components/basicComponents/AppointmentLineStatus.vue'
import AppointmentSmsStatus from '@/components/basicComponents/AppointmentSmsStatus.vue'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import store from '@/store'
import router from '@/router'
import DetailAppointment from './DetailAppointment.vue'
import { required } from '@/@core/utils/validation'
import AppointmentOptions from '@/components/prints/AppointmentOptions.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    Pagination,
    AppointmentStatus,
    ConverseDate,
    AppointmentLineStatus,
    AppointmentSmsStatus,
    DetailAppointment,
    AppointmentOptions,
  },
  props: {
    statusDataUpdate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const moment = require('moment')
    const appoint_date_start = ref(moment(new Date()).format('YYYY-MM-DD'))
    const isShowDate = ref(false)
    const userList = ref([])
    const user_id = ref('')
    const searchtext = ref('')
    const appointmentId = ref('')
    const dataTableList = ref([])
    const totalDataTableList = ref(0)
    const totalPage = ref(0)
    const columns = ref([
      {
        text: '#',
        value: 'appoint_id_pri',
        width: 30,
      },
      {
        text: i18n.t('option'),
        value: 'actions',
        sortable: false,
        align: 'end',
        width: 280,
      },
      {
        text: i18n.t('status'),
        value: 'appoint_status_id',
        width: 150,
      },
      {
        text: i18n.t('customer_patient'),
        value: 'customer_fullname',
        width: 200,
      },
      {
        text: i18n.t('tel'),
        value: 'appoint_tel',

        width: 100,
      },
      {
        text: i18n.t('time'),
        value: 'appoint_start',
        width: 120,
      },
      {
        text: i18n.t('date_of_appointment'),
        value: 'appoint_date',
        width: 120,
      },
      {
        text: i18n.t('topic'),
        value: 'appoint_topic',
        width: 200,
      },

      {
        text: i18n.t('doctor_appointment'),
        value: 'user_fullname',
        width: 200,
      },

      {
        text: `${i18n.t('status')} Line / SMS`,
        value: 'appoint_status_line',
        align: 'center',
        width: 170,
      },
    ])
    const loading = ref(false)
    const options = ref({})
    const footer = (i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
    const segmentId = ref(0)
    const isShowDetail = ref(false)
    const loadingUpdate = ref(false)
    const loadingCancel = ref(false)
    const comment = ref('')
    const formCancel = ref(null)
    const isCancel = ref(false)
    const isPrintAppointment = ref(false)
    const { userRoleList } = userRole
    const {
      appointmentLists, appointConnectGoogle, appointCheck, appointmentStatusUpdate,
    } = appointment

    onMounted(() => {
      fetchDataTable()
    })

    const fetchDataTable = () => {
      loading.value = true
      appointmentLists({
        user_id: user_id.value,
        appoint_date_start: appoint_date_start.value,
        appoint_date_end: appoint_date_start.value,
        searchtext: searchtext.value,
        active_page: options.value.page,
        per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
      }).then(res => {
        const {
          data, count, segment,
          count_of_page,
        } = res
        totalPage.value = count_of_page
        dataTableList.value = data
        totalDataTableList.value = count
        segmentId.value = segment
        loading.value = false
      })
    }

    const connect_google_plus = id => {
      appointConnectGoogle({
        appoint_id_pri: id,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        fetchDataTable()
      })
    }

    const showDetailCustomer = (idCus, idApp) => {
      appointCheck({
        appoint_id_pri: idApp,
        check: '1',
      }).then(() => {
        store.commit('app/setStatusUpdateHeader')
        router.push({
          name: 'DetailCustomer',
          params: { id: idCus },
        })
      })
    }

    userRoleList({
      role_id: ['1', '3'],
    }).then(res => {
      userList.value = res
      userList.value.unshift({
        user_id: '',
        user_fullname: i18n.t('all_doctors'),
      })
    })

    const pending = id => {
      loadingUpdate.value = true
      appointmentStatusUpdate({
        appoint_id_pri: id,
        appoint_status_id: '1',
        appoint_comment: '',
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        store.commit('app/setStatusUpdateHeader')
        fetchDataTable()
        loadingUpdate.value = false
      })
    }

    const confirmCancel = () => {
      const isFormValid = formCancel.value.validate()
      if (!isFormValid) return
      loadingCancel.value = true
      appointmentStatusUpdate({
        appoint_id_pri: appointmentId.value,
        appoint_status_id: '0',
        appoint_comment: comment.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        store.commit('app/setStatusUpdateHeader')
        fetchDataTable()
        loadingCancel.value = false
        isCancel.value = false
      })
    }

    watch([searchtext, appoint_date_start, user_id, options], (newvalue, oldvalue) => {
      if (
        newvalue[0] !== oldvalue[0]
        || newvalue[1] !== oldvalue[1]
        || newvalue[2] !== oldvalue[2]
      ) {
        options.value.page = 1
      }
      fetchDataTable()
    })

    watch(() => props.statusDataUpdate, value => {
      fetchDataTable()
    })

    return {
      appoint_date_start,
      isShowDate,
      userList,
      user_id,
      searchtext,
      isShowDetail,
      dataTableList,
      totalDataTableList,
      columns,
      loading,
      options,
      footer,
      segmentId,
      appointmentId,
      loadingUpdate,
      loadingCancel,
      comment,
      totalPage,
      formCancel,
      isCancel,
      confirmCancel,
      pending,
      connect_google_plus,
      fetchDataTable,
      showDetailCustomer,
      required,
      isPrintAppointment,
      icons: {
        mdiCalendarBlank,
        mdiGooglePlus,
        mdiCheck,
        mdiEyeOutline,
        mdiAlertOutline,
        mdiPrinter,
        mdiDeleteOutline,

      },
    }
  },
}
</script>
