import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'
import { api as api2 } from '../mainNode'

const appointmentList = async body => {
  //await refreshToken()
  const response = await api2
    .post({
      path: 'appoint/list',
      body,
    })
    .then(res => res.data || [])
    .catch(err => {
      console.log('get appointment list error: ', err)

      return []
    })

  return response
}

const appointmentLists = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'appoint/lists',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get appointment list list error: ', err)

      return {}
    })

  return response
}

const appointmentAdd = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'appoint',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('add appointment  error: ', err)

      return {}
    })

  return response
}

const appointmentUpdate = async body => {
  //await refreshToken()
  const response = await api
    .put({
      path: 'appoint',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('Update appointment  error: ', err)

      return {}
    })

  return response
}

const appointmentStatusUpdate = async body => {
  //await refreshToken()
  const response = await api
    .put({
      path: 'appoint/status',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('Update status appointment  error: ', err)

      return {}
    })

  return response
}

const appointmentCheck = async body => {
  //await refreshToken()
  const response = await api
    .put({
      path: 'appoint/check',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('add to Q error: ', err)

      return {}
    })

  return response
}

const appointmentCheckGoogle = async () => {
  //await refreshToken()
  const response = await api
    .get({
      path: 'appoint/checkgoogle',
    })
    .then(res => res || {})
    .catch(err => {
      console.log('add google error: ', err)

      return {}
    })

  return response
}

const appointmentSync = async body => {
  //await refreshToken()
  const response = await api
    .put({
      path: 'appoint/syn',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('sync google error: ', err)

      return {}
    })

  return response
}

const appointmentSms = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'appoint/send',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('send sms error: ', err)

      return {}
    })

  return response
}

const appointConnectGoogle = async body => {
  //await refreshToken()
  const response = await api
    .put({
      path: 'appoint/syn',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('connect google error: ', err)

      return {}
    })

  return response
}

const appointCheck = async body => {
  //await refreshToken()
  const response = await api
    .put({
      path: 'appoint/check',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('connect google error: ', err)

      return {}
    })

  return response
}

const appointGet = async param => {
  //await refreshToken()
  const response = await api
    .get({
      path: 'appoint',
      param,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get appoinment error: ', err)

      return {}
    })

  return response
}

const appointmentListNew = async param => {
  //await refreshToken()
  const response = await api2
    .get({
      path: 'appoint',
      param,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get appoinment error: ', err)

      return {}
    })

  return response
}

export default {
  appointmentList,
  appointmentLists,
  appointmentAdd,
  appointmentUpdate,
  appointmentStatusUpdate,
  appointmentCheck,
  appointmentCheckGoogle,
  appointmentSync,
  appointmentSms,
  appointConnectGoogle,
  appointCheck,
  appointGet,
  appointmentListNew,
}
