<template>
  <div>
    <v-card :loading='loading' class='app-calendar'>
      <v-card-title>
        {{ $t('daily_appointments_list') }}
        <v-spacer></v-spacer>
        <v-btn color='primary' class='d-none d-md-block' @click='isAddNewAppointment = true'>
          <v-icon class='me-1'>
            {{ icons.mdiPlus }}
          </v-icon>
          <span class='d-none d-sm-block'> {{ $t('add_appointments') }}</span>
        </v-btn>
        <v-btn color='primary' class='d-block d-md-none' fab icon outlined
               @click='isAddNewAppointment = true'>
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row no-gutters>
        <v-col cols='12'>
          <v-sheet>
            <v-toolbar :color="$vuetify.theme.dark ? '#302d4b' : 'light'" flat>
              <v-btn outlined class='mr-4' color='secondary' @click='setToday'>
                {{ $t('today') }}
              </v-btn>
              <v-btn
                v-if="type == 'tomorrow'"
                color='warning'
                :disabled='appointmentSelected.length <= 0'
                @click='isSendNotify = true'
              >
                <v-icon left> {{ icons.mdiEmailOutline }}</v-icon>
                {{ $t('send_notification_messages') }}
              </v-btn>
              <v-btn v-if='$refs.calendar' fab text small color='grey darken-2' @click='prev'>
                <v-icon small>
                  {{ icons.mdiChevronLeft }}
                </v-icon>
              </v-btn>
              <v-btn v-if='$refs.calendar' fab text small color='grey darken-2' @click='next'>
                <v-icon small>
                  {{ icons.mdiChevronRight }}
                </v-icon>
              </v-btn>
              <v-toolbar-title v-if='$refs.calendar'>
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn-toggle v-model='type' color='primary' class='d-none d-md-block'>
                <v-btn elevation='0' value='month'>
                  {{ $t('month') }}
                </v-btn>
                <v-btn elevation='0' value='week'>
                  {{ $t('week') }}
                </v-btn>
                <v-btn elevation='0' value='appointment'>
                  {{ $t('list_daily') }}
                </v-btn>
                <v-btn elevation='0' value='tomorrow'>
                  {{ $t('appointment_tomorrow') }}
                </v-btn>
              </v-btn-toggle>
            </v-toolbar>
          </v-sheet>
          <v-btn-toggle v-model='type' class='d-block d-md-none text-center mb-2' color='primary'
                        dense>
            <v-btn value='month' elevation-0>
              {{ $t('month') }}
            </v-btn>
            <v-btn value='week' elevation-0>
              {{ $t('week') }}
            </v-btn>
            <v-btn value='appointment' elevation-0>
              {{ $t('list_daily') }}
            </v-btn>
            <v-btn value='tomorrow' elevation-0>
              {{ $t('appointment_tomorrow') }}
            </v-btn>
          </v-btn-toggle>
          <v-sheet v-if="type == 'appointment'">
            <AppointmentList :status-data-update='statusDataUpdate' />
          </v-sheet>
          <v-sheet v-if="type == 'tomorrow'">
            <AppointmentTomorrow @onSelected='checkSelected'
                                 :status-data-update='statusDataUpdate' />
          </v-sheet>
          <v-sheet v-if="type == 'month' || type == 'week' || type == 'day'" height='640'>
            <v-calendar
              ref='calendar'
              v-model='focus'
              :events='events'
              :type='type'
              :event-color='getEventColor'
              :locale='$i18n.locale'
              @click:event='showEvent'
              @click:more='viewDay'
              @click:date='viewDay'
              @click:day='showAdd'
              @change='updateRange'
            ></v-calendar>
            <v-menu v-model='selectedOpen' :close-on-content-click='false'
                    :activator='selectedElement' max-width='400'>
              <v-card color='grey lighten-4'>
                <v-toolbar :color='selectedEvent.color'>
                  <v-toolbar-title class='white--text'
                                   v-html='selectedEvent.name'></v-toolbar-title>
                </v-toolbar>
                <v-card-text v-if='selectedEvent.data'>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                      <tr>
                        <td class='text-right'>{{ $t('status') }} :</td>
                        <td>
                          <v-checkbox
                            v-model='selectedEvent.data.appoint_syn_id'
                            :disabled='!statusEditor'
                            label='Google Calendar'
                            :true-value='1'
                            :false-value='0'
                            color='red darken-3'
                          ></v-checkbox>
                        </td>
                      </tr>
                      <tr>
                        <td class='text-right'>{{ $t('topic') }} :</td>
                        <td>
                          {{ !statusEditor ? selectedEvent.data.appoint_topic : '' }}
                          <v-text-field
                            v-if='statusEditor'
                            v-model='selectedEvent.data.appoint_topic'
                            outlined
                            hide-details
                            dense
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td class='text-right'>{{ $t('status') }} :</td>
                        <td>
                          {{
                            selectedEvent.data.appoint_status_id == '0'
                              ? $t('cancel')
                              : selectedEvent.data.appoint_status_id == '1'
                                ? $t('pending')
                                : $t('completed')
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class='text-right'>{{ $t('customer_patient') }} :</td>
                        <td>
                          {{ !statusEditor ? selectedEvent.data.customer_fullname : '' }}
                          <v-autocomplete
                            v-if='statusEditor'
                            v-model='selectedEvent.data.customer_id_pri'
                            :items='customerList'
                            :placeholder="$t('select_customers')"
                            auto-select-first
                            item-value='customer_id_pri'
                            disabled
                            return-object
                            hide-details
                            item-text='customer_fname'
                            outlined
                            dense
                          >
                            <template v-slot:item='{ item }'>
                              {{ item.customer_prefix }}
                              {{ item.customer_fname }}
                              {{ item.customer_lname }}
                              ({{ item.customer_id }})
                            </template>
                            <template v-slot:selection='{ item }'>
                              {{ item.customer_prefix }}
                              {{ item.customer_fname }}
                              {{ item.customer_lname }}
                              ({{ item.customer_id }})
                            </template>
                          </v-autocomplete>
                        </td>
                      </tr>
                      <tr>
                        <td class='text-right'>{{ $t('tel') }} :</td>
                        <td>
                          {{ !statusEditor ? selectedEvent.data.appoint_tel : '' }}
                          <v-text-field
                            v-if='statusEditor'
                            v-model.trim='selectedEvent.data.appoint_tel'
                            hide-details
                            type='number'
                            outlined
                            dense
                          >
                          </v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td class='text-right'>{{ $t('date_of_appointment') }} :</td>
                        <td>
                          <ConverseDate v-if='!statusEditor'
                                        :date='selectedEvent.data.appoint_date' />
                          <v-dialog
                            v-if='statusEditor'
                            ref='date'
                            v-model.trim='menu1'
                            :return-value.sync='selectedEvent.data.appoint_date'
                            width='290px'
                          >
                            <template v-slot:activator='{ on, attrs }'>
                              <v-text-field
                                v-model.trim='selectedEvent.data.appoint_date'
                                outlined
                                dense
                                hide-details
                                v-bind='attrs'
                                v-on='on'
                              >
                              </v-text-field>
                            </template>
                            <v-date-picker
                              v-model.trim='selectedEvent.data.appoint_date'
                              class=''
                              :locale='$i18n.locale'
                              scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn text color='primary' @click='menu1 = false'>
                                {{ $t('cancel') }}
                              </v-btn>
                              <v-btn text color='primary'
                                     @click='$refs.date.save(selectedEvent.data.appoint_date)'>
                                {{ $t('confirm') }}
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </td>
                      </tr>
                      <tr>
                        <td class='text-right'>{{ $t('time') }} :</td>
                        <td>
                          {{ !statusEditor ? selectedEvent.data.appoint_start : '' }}
                          -
                          {{ !statusEditor ? selectedEvent.data.appoint_end : '' }}
                          <v-select
                            v-if='statusEditor'
                            v-model='selectedEvent.data.appoint_start'
                            :items='timeList'
                            outlined
                            dense
                            hide-details
                            item-text='section_time'
                            item-values='section_time'
                          >
                          </v-select>
                        </td>
                      </tr>
                      <tr>
                        <td class='text-right'>{{ $t('doctor_appointment') }} :</td>
                        <td>
                          {{ !statusEditor ? selectedEvent.data.user_fullname : '' }}
                          <v-autocomplete
                            v-if='statusEditor'
                            v-model='selectedEvent.data.user_id'
                            :items='userList'
                            item-value='user_id'
                            :placeholder="$t('select_inspectors')"
                            outlined
                            hide-details
                            dense
                            item-text='user_fullname'
                          >
                          </v-autocomplete>
                        </td>
                      </tr>
                      <tr>
                        <td class='text-right'>{{ $t('note') }} :</td>
                        <td>
                          {{ !statusEditor ? selectedEvent.data.appoint_note : '' }}

                          <v-textarea
                            v-if='statusEditor'
                            v-model='selectedEvent.data.appoint_note'
                            hide-details
                            outlined
                            rows='3'
                          ></v-textarea>
                        </td>
                      </tr>
                      <tr>
                        <td class='text-right'>{{ $t('message') }} :</td>
                        <td>
                          <span v-html='selectedEvent.data.appoint_comment'></span>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn v-if='statusEditor' class='btn' color='primary' @click='updateAppointment'>
                    {{ $t('save') }}
                  </v-btn>
                  <v-btn v-if='!statusEditor' class='btn' color='primary'
                         @click='statusEditor = true'>
                    {{ $t('edit') }}
                  </v-btn>
                  <v-btn outlined color='secondary' @click='selectedOpen = false'>
                    {{ $t('cancel') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
<!--            <v-card-text class='mt-5 d-none d-md-block'>-->
<!--              <span v-for='(item, index) in userList' :key='index' class='me-4'>-->
<!--                <v-icon x-small :color='`${colors[index]}`'>-->
<!--                  {{ icons.mdiCircle }}-->
<!--                </v-icon>-->
<!--                <span :style='`color:${colors[index]};`'>-->
<!--                  {{ item.user_fullname }}-->
<!--                </span>-->
<!--              </span>-->
<!--            </v-card-text>-->
          </v-sheet>
        </v-col>
      </v-row>
    </v-card>
    <AddNewAppointment
      v-model='isAddNewAppointment'
      :date-add='dateAdd'
      :date-list='dateList'
      @onAdd='addDataSuccess();statusDataUpdate= !statusDataUpdate'
    />
    <SendNotify v-model='isSendNotify' :appointment-selected='appointmentSelected' />
  </div>
</template>

<script>
import { mdiChevronLeft, mdiChevronRight, mdiPlus, mdiEmailOutline, mdiCircle } from '@mdi/js'
import useAppointment from './useAppointment'
import AddNewAppointment from './AddNewAppointment.vue'
import AppointmentList from './AppointmentList.vue'
import AppointmentTomorrow from './AppointmentTomorrow.vue'
import SendNotify from './SendNotify.vue'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'

export default {
  components: {
    AddNewAppointment,
    AppointmentList,
    AppointmentTomorrow,
    SendNotify,
    ConverseDate,
  },
  setup() {
    return {
      ...useAppointment(),
      icons: {
        mdiPlus,
        mdiChevronLeft,
        mdiChevronRight,
        mdiEmailOutline,
        mdiCircle,
      },
    }
  },
}
</script>

<style lang='scss'>
@import '~@core/preset/preset/apps/calendar.scss';
</style>
