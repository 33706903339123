var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.isAddNewAppointment),callback:function ($$v) {_vm.isAddNewAppointment=$$v},expression:"isAddNewAppointment"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('add_appointments'))+" "),_c('v-spacer'),_c('v-checkbox',{staticClass:"pt-0 mt-0",attrs:{"label":_vm.$t('connect_google_calendar'),"true-value":"1","hide-details":"","false-value":"0","color":"error"},model:{value:(_vm.syncalendar),callback:function ($$v) {_vm.syncalendar=$$v},expression:"syncalendar"}})],1),_c('v-form',{ref:"formAddAppointment",on:{"submit":function($event){$event.preventDefault();return _vm.createAppointment.apply(null, arguments)}}},[_c('v-card-text',[_c('v-autocomplete',{attrs:{"items":_vm.dataBranchList,"item-text":"shop_name","item-value":"shop_id_pri","outlined":"","dense":"","label":_vm.$t('choose_a_shop'),"no-data-text":((_vm.$t('no_data')) + " !")},model:{value:(_vm.branchSelection),callback:function ($$v) {_vm.branchSelection=$$v},expression:"branchSelection"}}),_c('v-autocomplete',{attrs:{"items":_vm.customerList,"label":_vm.$t('choose_a_checker'),"placeholder":_vm.$t('input_three_charector'),"item-text":"customer_fullname","outlined":"","filter":_vm.customFilter,"dense":"","search-input":_vm.searchTextCustomer,"no-data-text":_vm.statusText,"loading":_vm.searchCustomerLoading,"auto-select-first":"","item-value":"customer_id_pri"},on:{"update:searchInput":function($event){_vm.searchTextCustomer=$event},"update:search-input":function($event){_vm.searchTextCustomer=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer_fullname)+" ("+_vm._s(item.customer_id)+") ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer_fullname)+" ("+_vm._s(item.customer_id)+") ")]}}]),model:{value:(_vm.customer_id_pri),callback:function ($$v) {_vm.customer_id_pri=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"customer_id_pri"}}),_c('v-text-field',{attrs:{"label":_vm.$t('tel'),"outlined":"","rules":[_vm.required],"dense":""},model:{value:(_vm.appoint_tel),callback:function ($$v) {_vm.appoint_tel=$$v},expression:"appoint_tel"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","rules":[_vm.required],"label":_vm.$t('topic')},model:{value:(_vm.appoint_topic),callback:function ($$v) {_vm.appoint_topic=$$v},expression:"appoint_topic"}}),_c('v-autocomplete',{attrs:{"items":_vm.userList,"rules":[_vm.required],"item-value":"user_id","placeholder":_vm.$t('select_inspectors'),"outlined":"","label":_vm.$t('doctor_appointment'),"dense":"","item-text":"user_fullname"},model:{value:(_vm.user_id),callback:function ($$v) {_vm.user_id=$$v},expression:"user_id"}}),_c('v-dialog',{ref:"date1",attrs:{"return-value":_vm.appoint_date,"width":"290px"},on:{"update:returnValue":function($event){_vm.appoint_date=$event},"update:return-value":function($event){_vm.appoint_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":_vm.$t('date_of_appointment'),"dense":"","rules":[_vm.required]},model:{value:(_vm.appoint_date),callback:function ($$v) {_vm.appoint_date=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"appoint_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isShowDate),callback:function ($$v) {_vm.isShowDate=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"isShowDate"}},[_c('v-date-picker',{attrs:{"locale":_vm.$i18n.locale,"scrollable":""},model:{value:(_vm.appoint_date),callback:function ($$v) {_vm.appoint_date=$$v},expression:"appoint_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":!_vm.appoint_date},on:{"click":function($event){return _vm.$refs.date1.save(_vm.appoint_date)}}},[_vm._v(" "+_vm._s(_vm.$t('confirm'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.isShowDate = false}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-spacer')],1)],1),_c('v-select',{attrs:{"items":_vm.timeList,"outlined":"","label":_vm.$t('time'),"rules":[_vm.required],"dense":"","item-text":"section_time","item-values":"section_time"},model:{value:(_vm.appoint_start),callback:function ($$v) {_vm.appoint_start=$$v},expression:"appoint_start"}}),_c('v-textarea',{attrs:{"outlined":"","label":_vm.$t('note'),"rows":"3"},model:{value:(_vm.appoint_note),callback:function ($$v) {_vm.appoint_note=$$v},expression:"appoint_note"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.loading,"disabled":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('confirm'))+" ")]),_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){return _vm.$emit('update:is-add-new-appointment',false)}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }