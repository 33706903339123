import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const bookTopicList = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'book/list',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.log('get book list error: ', err)

      return []
    })

  return response
}

const bookTopicAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'book',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('add book error: ', err)

      return {}
    })

  return response
}

const bookTopicUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'book',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('update book error: ', err)

      return {}
    })

  return response
}

const bookTopicStatusUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'book/status',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('update status book error: ', err)

      return {}
    })

  return response
}

const timeList = async () => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'book/sectionlist',
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.log('get time list error: ', err)

      return []
    })

  return response
}

const timeUpdate = async (path, body) => {
  ////await refreshToken()
  const response = await api
    .put({
      path,
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('udpate time error: ', err)

      return {}
    })

  return response
}

export default {
  bookTopicList, bookTopicAdd, bookTopicUpdate, bookTopicStatusUpdate, timeList, timeUpdate,
}
