<template>
  <v-dialog
    v-model="isSendNotify"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title>
        {{ $t("select_channel_and_check") }}
      </v-card-title>
      <v-card-text>
        <div
          align="center"
          class="error--text font-weight-semibold mb-2"
        >
          {{ $t("remaining_sms_credit") }} {{ smsData.shop_sms_all }}
          {{ $t("want_to_send") }} {{ appointmentSelected.length }} SMS
        </div>
        <v-select
          v-model="headsms_id"
          :items="topicSmsList"
          outlined
          :label="$t('topic')"
          item-value="headsms_id"
          item-text="headsms_name"
          dense
        ></v-select>
        <v-textarea
          v-model="headsms_id_text"
          hide-details
          rows="3"
          outlined
          dense
          :label="$t('additional_messages_sent')"
        >
          <template v-slot:label>
            {{ $t("additional_messages_sent") }}
            <span class="error--text">
              {{ $t("textAmount") }} : {{ checkLength }} {{ $t("useCredit") }} :
              {{ creditLength }}</span>
          </template>
        </v-textarea>
        <div class="d-md-flex">
          <v-checkbox
            v-model="send_line"
            hide-details
            true-value="1"
            false-value="0"
            :label="`${$t('send_notification')} Line`"
          ></v-checkbox>
          <v-checkbox
            v-model="send_sms"
            hide-details
            true-value="1"
            false-value="0"
            :label="`${$t('send_notification')} SMS`"
          ></v-checkbox>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="loading || +smsData.shop_sms_all < creditLength"
          @click="sendNotify"
        >
          {{ $t("save") }}
        </v-btn>
        <v-btn
          color="secondary"
          outlined
          @click="$emit('update:isSendNotify', false)"
        >
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import sms from '@/api/sms'
import document from '@/api/systemSetting/document'
import appointment from '@/api/appointment/appointment'
import store from '@/store'
import { i18n } from '@/plugins/i18n'

export default {
  model: {
    prop: 'isSendNotify',
    event: 'update:isSendNotify',
  },
  props: {
    isSendNotify: {
      type: Boolean,
      default: false,
    },
    appointmentSelected: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const headsms_id = ref('')
    const headsms_id_text = ref('')
    const send_line = ref('2')
    const send_sms = ref('2')
    const topicSmsList = ref([])
    const smsData = ref({})
    const loading = ref(false)
    const checkLength = ref(0)
    const creditLength = ref(props.appointmentSelected.length)
    const maxCredit = ref(75)

    sms.smsGet().then(res => {
      smsData.value = res
    })

    document.smsList().then(res => {
      topicSmsList.value = res
      headsms_id.value = res[0].headsms_id
    })
    const sendNotify = () => {
      loading.value = true
      appointment.appointmentSms({
        appoint_id_pri: props.appointmentSelected,
        headsms_id: headsms_id.value,
        headsms_id_text: headsms_id_text.value,
        send_line: send_line.value,
        send_sms: send_sms.value,
        lang: i18n.locale,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:isSendNotify', false)
        loading.value = false
      })
    }

    watch(() => props.isSendNotify, value => {
      if (value) {
        headsms_id_text.value = ''
        headsms_id.value = topicSmsList.value[0].headsms_id
        creditLength.value += props.appointmentSelected.length
      }
    })

    watch(headsms_id_text, value => {
      const a = 75
      if (value) {
        checkLength.value = value.length
        if (checkLength.value > maxCredit.value) {
          creditLength.value += props.appointmentSelected.length
          maxCredit.value += a
        } else if (value.length <= maxCredit.value - a) {
          maxCredit.value -= a
          const b = (checkLength.value / a) * props.appointmentSelected.length
          creditLength.value = parseInt(b)
        }
      } else {
        checkLength.value = 0
        creditLength.value = props.appointmentSelected.length
      }
    })

    return {
      headsms_id,
      headsms_id_text,
      send_line,
      send_sms,
      topicSmsList,
      smsData,
      loading,
      checkLength,
      creditLength,
      maxCredit,
      sendNotify,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
