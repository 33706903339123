<template>
  <v-dialog
    v-model="isPrintAppointment"
    persistent
    max-width="300"
  >
    <v-card>
      <v-card-title>
        {{ $t('choose_language') }}
      </v-card-title>
      <v-card-text>
        <v-radio-group
          v-model="selectLang"
          :label="`${$t('')}`"
        >
          <v-radio
            label="ไทย"
            value="1"
          ></v-radio>
          <v-radio
            label="English"
            value="2"
          ></v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="print"
        >
          {{ $t('confirm') }}
        </v-btn>
        <v-btn
          color="secondary"
          outlined
          @click="$emit('update:isPrintAppointment', false)"
        >
          {{ $t('cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import router from '@/router'

export default {
  model: {
    prop: 'isPrintAppointment',
    event: 'update:isPrintAppointment',
  },
  props: {
    isPrintAppointment: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const selectLang = ref('1')
    watch(() => props.isPrintAppointment, value => {
      if (value) {
        selectLang.value = '1'
      }
    })

    const print = () => {
      const name = 'printAppointment'
      const routeData = router.resolve({
        name,
        query: {
          id: String(props.id),
          lang: selectLang.value,
        },
      })
      window.open(routeData.href, '_blank')
    }

    return {
      selectLang,
      print,
    }
  },
}
</script>
