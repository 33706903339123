import appointment from '@/api/appointment/appointment'
import booking from '@/api/appointment/booking'
import customerSelection from '@/api/customerSelection'
import shop from '@/api/shop'
import userRole from '@/api/userRole'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import { mdiConsoleNetwork } from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'

export default function useAppointment() {
  const loading = ref(false)
  const moment = require('moment')
  const dialogSendNotify = ref(false)
  const selectAppointment = ref([])
  const menu1 = ref(false)
  const statusEditor = ref(false)
  const tab = ref('')
  const focus = ref(moment(new Date()).format('YYYY-MM-DD'))
  const type = ref('month')
  const typeToLabel = ref({
    month: 'month',
    week: 'week',
    day: 'day',
  })
  const selectedEvent = ref({})
  const statusLoadData = ref(false)
  const selectedElement = ref(null)
  const selectedOpen = ref(false)
  const sizeLg1 = ref('9')
  const sizeLg2 = ref('3')
  const events = ref([])
  const colors = ref([
    '#FF5252',
    '#40C4FF',
    '#FF4081',
    '#FFD740',
    '#7C4DFF',
    '#536DFE',
    '#18FFFF',
    '#B2FF59',
    '#E040FB',
    '#00E5FF',
    '#FFFF00',

    '#69F0AE',
    '#FF6E40',
    '#EEFF41',
    '#B0BEC5',
    '#00E676',
    '#90A4AE',
    '#651FFF',
    '#607D8B',
    '#F50057',
    '#448AFF',
    '#263238',
    '#FF1744',
    '#FFAB40',
    '#1DE9B6',
    '#D500F9',
    '#3D5AFE',
    '#212121',
    '#2979FF',
    '#1DE9B6',
    '#C6FF00',
    '#00B0FF',
    '#64FFDA',
  ])
  const user_id = ref('')
  const dateStartSelect = ref('')
  const dateEndSelect = ref('')
  const userList = ref([])
  const customerList = ref([])
  const timeList = ref([])
  const appointmentSelected = ref([])
  const smsData = ref('')
  const topicSmsList = ref([])
  const headsms_id = ref('')
  const headsms_id_text = ref('')
  const send_line = ref('2')
  const send_sms = ref('2')
  const appoint_id_pri = ref('')
  const shop_id_pri = ref('')
  const calendar = ref(null)
  const isSendNotify = ref(false)
  const statusDataUpdate = ref(false)
  const dateList = ref([])

  const isAddNewAppointment = ref(false)

  const dateAdd = ref('')

  const { appointmentList, appointmentUpdate } = appointment

  const { customerSelectionList } = customerSelection

  const { userRoleList } = userRole

  const { bookingList } = booking

  const { shop: shopGet } = shop

  const checkSelected = dataSelected => {
    appointmentSelected.value = dataSelected
  }

  onMounted(() => {
    getUserList()
    getProfile()
    getCustomerList()
    getTimeList()
    calendar.value.checkChange()
  })

  shopGet().then(res => {
    shop_id_pri.value = res.shop_id_pri
  })

  const showAdd = data => {
    let statusAdd = false
    for (let i = 0; i < calendar.value.events.length; i++) {
      const el = calendar.value.events[i]
      if (el.data.appoint_date == data.date) {
        if (el.data.holiday_status == 1) {
          statusAdd = true
          break
        }
      }
      if (i == calendar.value.events.length - 1) {
        if (!statusAdd) {
          dateAdd.value = data.date
          isAddNewAppointment.value = true
        }
      }
    }
  }

  const updateAppointment = () => {
    const body = {
      appoint_id_pri: selectedEvent.value.data.appoint_id_pri,
      customer_id_pri:
        typeof selectedEvent.value.data.customer_id_pri == 'string'
          ? selectedEvent.value.data.customer_id_pri
          : selectedEvent.value.data.customer_id_pri.customer_id_pri,
      appoint_date: selectedEvent.value.data.appoint_date,
      appoint_start: selectedEvent.value.data.appoint_start,
      user_id: selectedEvent.value.data.user_id,
      appoint_tel: selectedEvent.value.data.appoint_tel,
      appoint_topic: selectedEvent.value.data.appoint_topic,
      syncalendar: String(selectedEvent.value.data.appoint_syn_id),
      appoint_gid: selectedEvent.value.data.appoint_gid,
     // appoint_note: (shop_id_pri == 1 && syncalendar == 1) ? selectedEvent.value.data.appoint_note : '', // สำหรับ shop 1 และ syncalendar 1 ให้เป็นค่าว่าง
      appoint_note: selectedEvent.value.data.appoint_note,
      lang: i18n.locale,
    }

    appointmentUpdate(body).then(res => {
      store.commit('app/ALERT', {
        message: res.response ? res.message : res.data,
        color: res.response ? 'success' : 'error',
      })
      onAddData()
      selectedOpen.value = false
    })
  }

  const getTimeList = () => {
    const body = {
      booking_date: moment(new Date()).format('YYYY-MM-DD'),
    }
    bookingList(body).then(res => {
      timeList.value = res
    })
  }

  const getCustomerList = () => {
    const body = {
      searchtext: '',
      shop_id_pri: shop_id_pri.value,
    }
    customerSelectionList(body).then(res => {
      customerList.value = res
    })
  }

  const getProfile = () => {
    user_id.value = localStorage.getItem('user_id')
  }

  const getUserList = () => {
    const body = {
      role_id: ['1', '3'],
    }
    userRoleList(body).then(res => {
      userList.value = res
    })
  }

  const viewDay = ({ date }) => {
    focus.value = date
    type.value = 'day'
  }

  const getEventColor = event => event.color

  const setToday = () => {
    focus.value = moment(new Date()).format('YYYY-MM-DD')
    type.value = 'day'
  }

  const prev = () => {
    calendar.value.prev()
  }

  const next = () => {
    calendar.value.next()
  }

  const showEvent = ({ nativeEvent, event }) => {
    const open = () => {
      statusEditor.value = false
      selectedEvent.value = event
      selectedEvent.value.data.customer_id_pri = String(event.data.customer_id_pri)
      selectedEvent.value.data.appoint_start = event.data.appoint_start.slice(0, 5)
      selectedEvent.value.data.appoint_end = event.data.appoint_end.slice(0, 5)
      selectedElement.value = nativeEvent.target
      if (selectedEvent.value.data.holiday_status != '1') {
        requestAnimationFrame(() => requestAnimationFrame(() => (selectedOpen.value = true)))
      }
    }
    if (selectedOpen.value) {
      selectedOpen.value = false
      requestAnimationFrame(() => requestAnimationFrame(() => open()))
    } else {
      if (event.data.holiday_status === 0) {
        open()
      }
    }
    nativeEvent.stopPropagation()
  }

  const onAddData = async () => {
    await calendar.value.next()
    calendar.value.prev()
    type.value = 'month'
    loading.value = false
  }

  const updateRange = ({ start, end }) => {
    loading.value = true
    events.value = []
    const body = {
      user_id: '',
      appoint_date_start: start.date,
      appoint_date_end: end.date,
      searchText: '',
    }
    appointmentList(body).then(res => {
      res.forEach(element => {
        let i = 0
        for (const item of userList.value) {
          if (element.user_id == item.user_id) {
            break
          }
          i++
        }
        let namex = ''
        if (element.holiday_status == '1') {
          namex = `${i18n.t('holidayClinic')} (${element.appoint_topic})`
        } else {
          namex = `${element.appoint_topic} (${
            element.appoint_status_id == '0'
              ? i18n.t('cancel')
              : element.appoint_status_id == '1'
                ? i18n.t('pending')
                : i18n.t('completed')
          })`
        }
        events.value.push({
          name: namex,
          start: `${element.appoint_date} ${element.appoint_start}`,
          end: `${element.appoint_date} ${element.appoint_end}`,
          data: element,
          color: element.appoint_status_id == 0 ? 'error' : element.appoint_status_id == 1 ? 'warning' : 'success',
        })
        if (element.holiday_status == '1') {
          dateList.value.push({ date: element.appoint_date, name: element.appoint_topic })
        }
      })

      loading.value = false
    })
  }

  const addDataSuccess = () => {
    loading.value = true
    onAddData()
    // calendar.value.updateTimes()
  }

  watch(statusEditor, value => {
    if (value) {
      selectedEvent.value.data.user_id = String(selectedEvent.value.data.user_id)
    }
  })

  // const rnd = (a, b) => Math.floor((b - a + 1) * Math.random()) + a

  return {
    isAddNewAppointment,
    statusDataUpdate,
    loading,
    dialogSendNotify,
    selectAppointment,
    menu1,
    statusEditor,
    tab,
    focus,
    type,
    typeToLabel,
    selectedEvent,
    statusLoadData,
    dateAdd,
    selectedElement,
    selectedOpen,
    sizeLg1,
    sizeLg2,
    events,
    colors,
    user_id,
    dateStartSelect,
    dateEndSelect,
    userList,
    customerList,
    timeList,
    appointmentSelected,
    smsData,
    topicSmsList,
    headsms_id,
    headsms_id_text,
    send_line,
    send_sms,
    dateList,
    appoint_id_pri,
    calendar,
    shop_id_pri,
    isSendNotify,
    showAdd,
    updateAppointment,
    getTimeList,
    getCustomerList,
    getProfile,
    getUserList,
    viewDay,
    getEventColor,
    setToday,
    prev,
    next,
    showEvent,
    onAddData,
    updateRange,
    addDataSuccess,
    checkSelected,
  }
}
