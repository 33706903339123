import { i18n } from '@/plugins/i18n'
import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const bookingList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'booking/list',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.log('get booking list error: ', err)

      return []
    })

  return response
}
const bookingAdd = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'booking',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('add booking error: ', err)

      return {}
    })

  return response
}

const bookingUpdate = async body => {
  //await refreshToken()
  const response = await api
    .put({
      path: 'booking',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('update booking error: ', err)

      return {}
    })

  return response
}

const bookingRemove = async id => {
  //await refreshToken()
  const response = await api
    .delete({
      path: 'booking',
      param: `${id}/${i18n.locale}`,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('delete booking error: ', err)

      return {}
    })

  return response
}

export default {
  bookingList, bookingAdd, bookingUpdate, bookingRemove,
}
