<template>
  <v-icon
    small
    :color="status == 1?'warning':status == 2?'success':'error'"
  >
    {{ status == 1? icons.mdiEmailAlertOutline:status == 2?icons.mdiEmailOpenOutline:icons.mdiClose }}
  </v-icon>
</template>

<script>

import { mdiEmailOpenOutline, mdiEmailAlertOutline, mdiClose } from '@mdi/js'

export default {
  props: { status: Number },
  setup() {
    return {
      icons: {
        mdiEmailOpenOutline, mdiEmailAlertOutline, mdiClose,
      },
    }
  },
}
</script>
