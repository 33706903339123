<template>
  <div>
    <v-dialog
      v-model='isAddNewAppointment'
      persistent
      max-width='500'
    >
      <v-card>
        <v-card-title>
          {{ $t('add_appointments') }}
          <v-spacer></v-spacer>
          <v-checkbox
            v-model='syncalendar'
            :label="$t('connect_google_calendar')"
            true-value='1'
            class='pt-0 mt-0'
            hide-details
            false-value='0'
            color='error'
          ></v-checkbox>
        </v-card-title>
        <v-form
          ref='formAddAppointment'
          @submit.prevent='createAppointment'
        >
          <v-card-text>
            <v-autocomplete
              v-model='branchSelection'
              :items='dataBranchList'
              item-text='shop_name'
              item-value='shop_id_pri'
              outlined
              dense
              :label="$t('choose_a_shop')"
              :no-data-text="`${$t('no_data')} !`"
            >
            </v-autocomplete>
            <v-autocomplete
              v-model.trim='customer_id_pri'
              :items='customerList'
              :label="$t('choose_a_checker')"
              :placeholder="$t('input_three_charector')"
              item-text='customer_fullname'
              outlined
              :filter='customFilter'
              dense
              :search-input.sync='searchTextCustomer'
              :no-data-text='statusText'
              :loading='searchCustomerLoading'
              auto-select-first
              item-value='customer_id_pri'
            >
              <template v-slot:item='{ item }'>
                {{ item.customer_fullname }}
                ({{ item.customer_id }})
              </template>
              <template v-slot:selection='{ item }'>
                {{ item.customer_fullname }}
                ({{ item.customer_id }})
              </template>
            </v-autocomplete>
            <v-text-field
              v-model='appoint_tel'
              :label="$t('tel')"
              outlined
              :rules='[required]'
              dense
            >
            </v-text-field>
            <v-text-field
              v-model='appoint_topic'
              outlined
              dense
              :rules='[required]'
              :label="$t('topic')"
            >
            </v-text-field>
            <v-autocomplete
              v-model='user_id'
              :items='userList'
              :rules='[required]'
              item-value='user_id'
              :placeholder="$t('select_inspectors')"
              outlined
              :label="$t('doctor_appointment')"
              dense
              item-text='user_fullname'
            >
            </v-autocomplete>
            <v-dialog
              ref='date1'
              v-model.trim='isShowDate'
              :return-value.sync='appoint_date'
              width='290px'
            >
              <template v-slot:activator='{ on, attrs }'>
                <v-text-field
                  v-model.trim='appoint_date'
                  outlined
                  :label="$t('date_of_appointment')"
                  dense
                  :rules='[required]'
                  v-bind='attrs'
                  v-on='on'
                ></v-text-field>
              </template>
              <v-date-picker
                v-model='appoint_date'
                class=''
                :locale='$i18n.locale'
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color='primary'
                  :disabled='!appoint_date'
                  @click='$refs.date1.save(appoint_date)'
                >
                  {{ $t('confirm') }}
                </v-btn>
                <v-btn
                  text
                  color='secondary'
                  @click='isShowDate = false'
                >
                  {{ $t('cancel') }}
                </v-btn>

                <v-spacer></v-spacer>
              </v-date-picker>
            </v-dialog>
            <v-select
              v-model='appoint_start'
              :items='timeList'
              outlined
              :label="$t('time')"
              :rules='[required]'
              dense
              item-text='section_time'
              item-values='section_time'
            >
            </v-select>
            <v-textarea
              v-model='appoint_note'
              outlined
              :label="$t('note')"
              rows='3'
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color='primary'
              type='submit'
              :loading='loading'
              :disabled='loading'
            >
              {{ $t('confirm') }}
            </v-btn>
            <v-btn
              color='secondary'
              outlined
              @click="$emit('update:is-add-new-appointment',false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import customerSelection from '@/api/customerSelection'
import shop from '@/api/shop'
import { i18n } from '@/plugins/i18n'
import userRole from '@/api/userRole'
import bookTopic from '@/api/appointment/bookTopic'
import { required } from '@/@core/utils/validation'
import appointment from '@/api/appointment/appointment'
import store from '@/store'
import branch from '@/api/branch'

export default {
  model: {
    prop: 'isAddNewAppointment',
    event: 'update:is-add-new-appointment',
  },
  props: {
    isAddNewAppointment: {
      type: Boolean,
      default: false,
    },
    dateAdd: {
      type: String,
      default: '',
    },
    dateList: {
      type: Array,
      default: () => [],
    },

  },
  setup(props, { emit }) {
    const formAddAppointment = ref(null)
    const appoint_tel = ref('')
    const appoint_date = ref('')
    const appoint_start = ref('')
    const user_id = ref('')
    const appoint_topic = ref('')
    const appoint_note = ref('')
    const syncalendar = ref('')
    const searchCustomerLoading = ref(false)
    const statusText = ref(i18n.t('input_three_charector'))
    const searchTextCustomer = ref('')
    const customerList = ref([])
    const customer_id_pri = ref('')
    const shop_id_pri = ref('')
    const userList = ref([])
    const isShowDate = ref(false)
    const timeList = ref([])
    const loading = ref(false)
    const isDate = ref({})
    const isDateError = ref(true)
    const dataBranchList = ref([])
    const branchSelection = ref('')
    const { shop: getShop } = shop
    const { customerSelectionList } = customerSelection
    const { userRoleList } = userRole
    const { timeList: timeListGet } = bookTopic
    const { appointmentAdd } = appointment

    branch.branchList().then(res => {
      dataBranchList.value = res
      getShop().then(res => {
        dataBranchList.value.unshift(res)
        branchSelection.value = res.shop_id_pri
      })
    })

    timeListGet().then(res => {
      timeList.value = res
      appoint_start.value = res[0].section_time
    })

    getShop().then(res => {
      shop_id_pri.value = res.shop_id_pri
    })

    userRoleList({
      role_id: ['1', '3'],
    }).then(res => {
      userList.value = res
      user_id.value = localStorage.getItem('user_id')
    })

    const createAppointment = () => {
      const isFormValid = formAddAppointment.value.validate()
      if (!isFormValid) return
      loading.value = true
      appointmentAdd({
        shop_id_pri: branchSelection.value,
        customer_id_pri: customer_id_pri.value,
        appoint_date: appoint_date.value,
        appoint_start: appoint_start.value,
        user_id: user_id.value,
        appoint_tel: appoint_tel.value,
        appoint_topic: appoint_topic.value,
        syncalendar: syncalendar.value,
        appoint_note : appoint_note.value,
        lang: i18n.locale,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.response ? res.message : res.data,
          color: res.response ? 'success' : 'error',
        })
        store.commit('app/setStatusUpdateHeader')
        emit('update:is-add-new-appointment', false)
        loading.value = false
        emit('onAdd')
      })
    }

    const customFilter = (item, queryText) => {
      const textOne = item.customer_fullname.toLowerCase()
      const textThree = item.customer_id.toLowerCase()
      const textFour = item.customer_tel.toLowerCase()
      const searchText = queryText.toLowerCase()

      return (
        textOne.indexOf(searchText) > -1
        || textThree.indexOf(searchText) > -1
        || textFour.indexOf(searchText) > -1
      )
    }

    watch(searchTextCustomer, value => {
      if (value && value.length >= 3) {
        statusText.value = i18n.t('data_loading')
        searchCustomerLoading.value = true
        getCustomerList()
      } else {
        customerList.value = []
        statusText.value = i18n.t('input_three_charector')
      }
    })

    watch(customer_id_pri, value => {
      if (value) {
        syncalendar.value = customerList.value.find(item => item.customer_id_pri === value).customer_email.includes('@gmail.com')
          ? '1'
          : '0'
        appoint_tel.value = customerList.value.find(item => item.customer_id_pri === value).customer_tel
      } else {
        syncalendar.value = ''
        appoint_tel.value = ''
      }
    })

    watch(() => props.isAddNewAppointment, newvalue => {
      if (newvalue) {
        appoint_date.value = JSON.parse(JSON.stringify(props.dateAdd))
        customer_id_pri.value = ''
        appoint_tel.value = ''
        appoint_topic.value = ''
        appoint_note.value = ''
      }
    })

    watch(appoint_date, value => {
      isDateError.value = true
      isDate.value = props.dateList.find(item => item.date == value)
      if (isDate.value) {
        isDateError.value = false
        store.commit('app/ALERT', {
          message: `${i18n.t('appointmentFailed')} (${isDate.value.name})`,
          color: 'error',
        })
        appoint_date.value = ''
      }
    })

    const getCustomerList = () => {
      customerSelectionList({
        searchtext: searchTextCustomer.value,
        shop_id_pri: branchSelection.value,
      }).then(res => {
        customerList.value = res
        searchCustomerLoading.value = false
        statusText.value = !res.length ? i18n.t('no_data') : i18n.t('input_three_charector')
      })
    }

    watch(searchTextCustomer, value => {
      if (value && value.length >= 3) {
        statusText.value = i18n.t('data_loading')
        searchCustomerLoading.value = true
        getCustomerList()
      } else {
        customerList.value = []
        statusText.value = i18n.t('input_three_charector')
      }
    })

    watch(branchSelection, () => {
      searchTextCustomer.value = ''
      appoint_tel.value = ''
      statusText.value = i18n.t('input_three_charector')
      customerList.value = []
      customer_id_pri.value = ''
    })

    return {
      loading,
      required,
      timeList,
      formAddAppointment,
      appoint_tel,
      appoint_date,
      appoint_start,
      user_id,
      appoint_topic,
      appoint_note,
      syncalendar,
      searchCustomerLoading,
      statusText,
      searchTextCustomer,
      customerList,
      customer_id_pri,
      shop_id_pri,
      isDate,
      userList,
      dataBranchList,
      branchSelection,
      isShowDate,
      getCustomerList,
      createAppointment,
      isDateError,
      customFilter,
    }
  },
}
</script>

<style lang='scss' scoped>

</style>
