<template>
  <v-chip
    :color="type == 1?'warning':type == 2?'success':type == 3?'primary':'error'"
    :class="type==1?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg warning--text`:type==2?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg success--text`:type==3?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg primary--text`:`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg error--text`"
    small
  >
    {{ type == 1 ? $t('pending') : type == 2 ? $t('completed') :type == 3 ? $t('waitConfirm'): $t('cancel') }}
  </v-chip>
</template>

<script>

export default {
  props: { type: Number },
  setup() {
    return {

    }
  },
}
</script>
