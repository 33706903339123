<template>
  <v-dialog
    v-model="isShowDetail"
    persistent
    max-width="600"
  >
    <v-card>
      <v-card-title>
        {{ $t('detail') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          icon
          @click="statusEditor = true"
        >
          <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <tbody>
              <tr>
                <td class=" text-right">
                  {{ $t("status") }} :
                </td>
                <td>
                  <v-checkbox
                    v-model="detailAppoint.appoint_syn_id"
                    :disabled="!statusEditor"
                    label="Google Calendar"
                    :true-value="1"
                    :false-value="0"
                    color="error"
                  ></v-checkbox>
                </td>
              </tr>
              <tr>
                <td class=" text-right">
                  {{ $t("topic") }} :
                </td>
                <td>
                  {{ !statusEditor ? detailAppoint.appoint_topic : "" }}
                  <v-text-field
                    v-if="statusEditor"
                    v-model="detailAppoint.appoint_topic"
                    outlined
                    class="my-2"
                    hide-details
                    dense
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td class=" text-right">
                  {{ $t("status") }} :
                </td>
                <td>
                  {{
                    detailAppoint.appoint_status_id == 0
                      ? $t("cancel")
                      : detailAppoint.appoint_status_id == 1
                        ? $t("pending")
                        : $t("success")
                  }}
                </td>
              </tr>
              <tr>
                <td class=" text-right">
                  {{ $t("customer_patient") }} :
                </td>
                <td>
                  {{ !statusEditor ? detailAppoint.customer_fullname : "" }}
                  <v-autocomplete
                    v-if="statusEditor"
                    v-model="detailAppoint.customer_id_pri"
                    :items="customerList"
                    :label="$t('choose_a_checker')"
                    :placeholder="$t('input_three_charector')"
                    item-text="customer_fullname"
                    outlined
                    :filter="customFilter"
                    dense
                    class="my-2"
                    :search-input.sync="searchTextCustomer"
                    hide-details
                    :no-data-text="statusText"
                    :loading="searchCustomerLoading"
                    auto-select-first
                    item-value="customer_id_pri"
                  >
                    <template v-slot:item="{ item }">
                      {{ item.customer_fullname }}
                      ({{ item.customer_id }})
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ item.customer_fullname }}
                      ({{ item.customer_id }})
                    </template>
                  </v-autocomplete>
                </td>
              </tr>
              <tr>
                <td class=" text-right">
                  {{ $t("tel") }} :
                </td>
                <td>
                  {{ !statusEditor ? detailAppoint.appoint_tel : "" }}
                  <v-text-field
                    v-if="statusEditor"
                    v-model.trim="detailAppoint.appoint_tel"
                    hide-details
                    type="number"
                    outlined
                    class="my-2"
                    dense
                  >
                  </v-text-field>
                </td>
              </tr>
              <tr>
                <td class=" text-right">
                  {{ $t("date_of_appointment") }} :
                </td>
                <td>
                  {{ !statusEditor ? detailAppoint.appoint_date : "" }}
                  <v-dialog
                    v-if="statusEditor"
                    ref="datePicker"
                    v-model.trim="isShowDate"
                    :return-value.sync="detailAppoint.appoint_date"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model.trim="detailAppoint.appoint_date"
                        outlined
                        dense
                        class="my-2"
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model.trim="detailAppoint.appoint_date"
                      class=""
                      :locale="$i18n.locale"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="isShowDate = false"
                      >
                        {{ $t("cancel") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.datePicker.save(detailAppoint.appoint_date)"
                      >
                        {{ $t("confirm") }}
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </td>
              </tr>
              <tr>
                <td class=" text-right">
                  {{ $t("time") }} :
                </td>
                <td>
                  {{ !statusEditor ? detailAppoint.appoint_start : "" }}

                  {{ !statusEditor ? detailAppoint.appoint_end : "" }}
                  <v-select
                    v-if="statusEditor"
                    v-model="detailAppoint.appoint_start"
                    :items="timeList"
                    outlined
                    dense
                    class="my-2"
                    hide-details
                    item-text="section_time"
                    item-values="section_time"
                  >
                  </v-select>
                </td>
              </tr>
              <tr>
                <td class=" text-right">
                  {{ $t("doctor_appointment") }} :
                </td>
                <td>
                  {{ !statusEditor ? detailAppoint.user_fullname : "" }}
                  <v-autocomplete
                    v-if="statusEditor"
                    v-model.trim="detailAppoint.user_id"
                    :items="userList"
                    item-value="user_id"
                    :placeholder="$t('select_inspectors')"
                    outlined
                    hide-details
                    dense
                    :no-data-text="$t('no_information')"
                    class="my-2"
                    item-text="user_fullname"
                  >
                  </v-autocomplete>
                </td>
              </tr>
              <tr>
                <td class=" text-right">
                  {{ $t("note") }} :
                </td>
                <td>
                  {{ !statusEditor ? detailAppoint.appoint_note : "" }}

                  <v-textarea
                    v-if="statusEditor"
                    v-model="detailAppoint.appoint_note"
                    hide-details
                    outlined
                    class="my-2"
                    rows="3"
                  ></v-textarea>
                </td>
              </tr>
              <tr>
                <td class=" text-right">
                  {{ $t("message") }} :
                </td>
                <td>
                  {{ detailAppoint.appoint_comment }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="statusEditor"
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="updateAppointment"
        >
          {{ $t("save") }}
        </v-btn>
        <v-btn
          color="secondary"
          outlined
          @click="(statusEditor = false), ($emit('update:isShowDetail',false))"
        >
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiPencilOutline } from '@mdi/js'
import appointment from '@/api/appointment/appointment'
import customerSelection from '@/api/customerSelection'
import { i18n } from '@/plugins/i18n'
import userRole from '@/api/userRole'
import booking from '@/api/appointment/booking'
import store from '@/store'

export default {
  model: {
    prop: 'isShowDetail',
    event: 'update:isShowDetail',
  },
  props: {
    isShowDetail: {
      type: Boolean,
      default: false,
    },
    appointId: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const moment = require('moment')
    const detailAppoint = ref({})
    const statusEditor = ref(false)
    const isShowDate = ref(false)
    const customerList = ref([])
    const statusText = ref('')
    const searchCustomerLoading = ref(false)
    const searchTextCustomer = ref('')
    const userList = ref([])
    const timeList = ref([])
    const loading = ref(false)

    const getCustomerList = () => {
      customerSelection.customerSelectionList({
        searchtext: searchTextCustomer.value,
      }).then(res => {
        customerList.value = res
        searchCustomerLoading.value = false
      })
    }

    const getUserList = () => {
      userRole.userRoleList({
        role_id: [1, 3],
      }).then(res => {
        userList.value = res
      })
    }

    const getTimeList = () => {
      booking.bookingList({
        booking_date: moment(new Date()).format('YYYY-MM-DD'),
      }).then(res => {
        timeList.value = res
      })
    }

    const customFilter = (item, queryText) => {
      const textOne = item.customer_fullname.toLowerCase()
      const textThree = item.customer_id.toLowerCase()
      const textFour = item.customer_tel.toLowerCase()
      const searchText = queryText.toLowerCase()

      return (
        textOne.indexOf(searchText) > -1
      || textThree.indexOf(searchText) > -1
      || textFour.indexOf(searchText) > -1
      )
    }

    const getAppointment = () => {
      appointment.appointGet(props.appointId).then(res => {
        detailAppoint.value = res
      })
    }

    const updateAppointment = () => {
      loading.value = true
      const body = {
        appoint_id_pri: detailAppoint.value.appoint_id_pri,
        customer_id_pri:
        typeof detailAppoint.value.customer_id_pri == 'string'
          ? detailAppoint.value.customer_id_pri
          : detailAppoint.value.customer_id_pri.customer_id_pri,
        appoint_date: detailAppoint.value.appoint_date,
        appoint_start: detailAppoint.value.appoint_start,
        user_id: detailAppoint.value.user_id,
        appoint_tel: detailAppoint.value.appoint_tel,
        appoint_topic: detailAppoint.value.appoint_topic,
        syncalendar: detailAppoint.value.appoint_syn_id.toString(),
        appoint_gid: detailAppoint.value.appoint_gid,
        appoint_note: detailAppoint.value.appoint_note,
        lang: i18n.locale,
      }
      appointment.appointmentUpdate(body).then(res => {
        store.commit('app/ALERT', {
          message: res.response ? res.message : res.data,
          color: res.response ? 'success' : 'error',
        })
        getAppointment()
        statusEditor.value = false
        loading.value = false
        emit('onUpdate')
      })
    }

    watch(() => detailAppoint.value.customer_id_pri, value => {
      if (value) {
        detailAppoint.value.appoint_tel = customerList.value.find(item => item.customer_id_pri === value).customer_tel || ''
      }
    })

    watch(() => props.isShowDetail, newVal => {
      if (newVal) {
        getAppointment()
        getCustomerList()
        getUserList()
        getTimeList()
      }
    })

    return {
      statusEditor,
      detailAppoint,
      isShowDate,
      customerList,
      searchCustomerLoading,
      searchTextCustomer,
      statusText,
      userList,
      loading,
      customFilter,
      updateAppointment,
      timeList,
      icons: { mdiPencilOutline },
    }
  },
}
</script>
